<template>
    <div class=" mx-auto xl:container ">
        <div class="sm:my-10 xl:mx-10">
            <h1 class="dark:text-white text-gray-800 text-2xl">Экспорт данных</h1>
            <Export></Export>
        </div>
    </div>
</template>

<script>

import Export from '@/components/common/Export'
export default {
    name: "ExportData",
    components:{
        Export
    }

}
</script>

<style scoped>

</style>